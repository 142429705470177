import { memo } from 'react';

import { makeStyles } from '@material-ui/core';
import Favorite from '../../../../assets/svg/favorite.svg';
import { ContainerDesktop } from '../styles';

interface DesktopButtonProps {
  toggle: boolean;
  disableButton: boolean;
  handleFavorite(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const useStyles = makeStyles({
  dirt: {
    '& g path': {
      fill: 'white',
    },
  },
});

const DesktopButton: React.FC<DesktopButtonProps> = ({
  toggle,
  handleFavorite,
  disableButton,
}) => {
  const { dirt } = useStyles();

  return (
    <ContainerDesktop
      aria-label="favoritar"
      onClick={handleFavorite}
      disabled={disableButton}
      className={toggle && dirt}
    >
      <Favorite />
      SALVAR
    </ContainerDesktop>
  );
};

export default memo(DesktopButton);
