import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
});

export interface IBackButton {
  href: string;
}

let isButton = false;

const BackButton: React.FC<IBackButton> = ({ href }) => {
  const router = useRouter();
  const { root } = useStyles();

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== href && isButton) {
        window.location.href = href;
        isButton = false;
        return false;
      }
      return true;
    });
  }, [href, router]);

  const goBack = () => {
    isButton = true;
    if (window.history.state.idx > 0) {
      router.back();
    } else {
      router.replace(href);
    }
  };

  return (
    <IconButton className={root} aria-label="voltar" onClick={goBack}>
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export default BackButton;
