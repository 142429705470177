/* eslint no-restricted-syntax: 0 */
import Script from 'next/script';
import React, { useCallback } from 'react';

import { Container } from './styles';

const startseAgencyCode = '182254972';

export interface IAds {
  unitId: string;
  agencyCode: string;
  size: Array<number[]> | number[] | string;
  sizeMapping?: Array<{
    pageSize: number[];
    adSizes: Array<number[]>;
  }>;
}

export const allAds = {
  'platform-home': {
    unitId: 'platform-home',
    agencyCode: startseAgencyCode,
    size: [
      [300, 100],
      [970, 90],
    ],
    sizeMapping: [
      {
        pageSize: [640, 480],
        adSizes: [[970, 90]],
      },
      {
        pageSize: [0, 0],
        adSizes: [[300, 100]],
      },
    ],
  } as IAds,
  'platform-home-mobile': {
    unitId: 'platform-home',
    agencyCode: startseAgencyCode,
    size: [
      [300, 100],
      [970, 90],
    ],
    sizeMapping: [
      {
        pageSize: [640, 480],
        adSizes: [[970, 90]],
      },
      {
        pageSize: [0, 0],
        adSizes: [[300, 100]],
      },
    ],
  } as IAds,
  'articles-content': {
    unitId: 'articles-content',
    agencyCode: startseAgencyCode,
    size: [
      [300, 250],
      [970, 250],
    ],
    sizeMapping: [
      {
        pageSize: [640, 480],
        adSizes: [[970, 250]],
      },
      {
        pageSize: [0, 0],
        adSizes: [[300, 250]],
      },
    ],
  } as IAds,
};

const slots = [];

interface AdsProps {
  ad: IAds;
  props?: Object;
}

const Ads: React.FC<AdsProps> = ({ ad, props }) => {
  const { agencyCode, size, unitId, sizeMapping } = ad;

  const instaceTagManager = useCallback(() => {
    const { googletag } = window as any;
    if (typeof window !== undefined) {
      if (slots[unitId]) {
        if (props) {
          for (const [key, value] of Object.entries(props)) {
            slots[unitId].setTargeting(key, value);
          }
        }
        googletag.cmd.push(() => {
          googletag.display(unitId);
          googletag.pubads().refresh([slots[unitId]]);
        });
      } else {
        googletag.cmd.push(() => {
          let loadedSlot;
          if (sizeMapping) {
            const mapping = googletag.sizeMapping();
            for (const map of sizeMapping) {
              mapping.addSize(map.pageSize, map.adSizes);
            }
            const buildMapping = mapping.build();

            loadedSlot = googletag
              .defineSlot(`/${agencyCode}/${unitId}`, size, unitId)
              .defineSizeMapping(buildMapping)
              .addService(googletag.pubads());
          } else {
            loadedSlot = googletag
              .defineSlot(`/${agencyCode}/${unitId}`, size, unitId)
              .addService(googletag.pubads());
          }
          if (props) {
            for (const [key, value] of Object.entries(props)) {
              loadedSlot.setTargeting(key, value);
            }
          }
          slots[unitId] = loadedSlot;
          googletag.pubads().enableSingleRequest();
          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();
        });
        googletag.cmd.push(() => {
          googletag.display(unitId);
        });
      }
    }
  }, [agencyCode, size, props, unitId, sizeMapping]);

  return (
    <>
      <Script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        onLoad={instaceTagManager}
      />
      <Container>
        <div id={unitId} />
      </Container>
    </>
  );
};

export default Ads;
