import { useState, useCallback, useEffect, useMemo } from 'react';

import { useUser } from '@hooks/user';
import { FavoriteTypes, useFavorites } from '@hooks/favorite';
import { useAuth } from '@hooks/auth';
import { useRouter } from 'next/router';
import { useTracking } from '@hooks/tracking';
import { FavoriteService } from '@services/content';
import { ContentType } from '@utils/contentType';
import IFavorite from '@models/favorite.interface';
import { IArticleProduct } from '@models/article.interface';
import { AlertConfirm } from '@components/core';
import MobileButton from './Button/Mobile';
import DesktopButton from './Button/Desktop';

interface FavoriteProps {
  contentId: string;
  type: ContentType;
  buttonType: 'mobile' | 'desktop';
  theme?: 'white' | 'dark' | 'superdark';
  contentRelatedProduct?: IArticleProduct;
  contentRelatedProductId?: number;
  contentSlug?: string;
  onClick?: (contentId: string) => void;
  showModalConfirm?: boolean;
}

const FavoriteButton: React.FC<FavoriteProps> = ({
  contentId,
  buttonType,
  type,
  theme,
  contentRelatedProduct,
  contentRelatedProductId,
  contentSlug,
  onClick,
  showModalConfirm,
}) => {
  const [content, setContent] = useState<IFavorite | undefined>(undefined);
  const [toggle, setToggle] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { state, dispatch } = useFavorites();
  const { user } = useUser();
  const { setShowModalLogin } = useAuth();
  const { track } = useTracking();
  const router = useRouter();
  const { slug, video } = router.query;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user?._id) {
      const favored = state.favorites.find(
        favorite => favorite?.contentId === contentId,
      );
      setToggle(!!favored);
      setContent(favored);
    }
  }, [contentId, state?.favorites, user?._id]);

  const handleCreateFavorite = useCallback(async () => {
    setToggle(true);
    setDisableButton(true);
    const response = await FavoriteService.create(user._id, contentId, type);
    const trackSlug = contentSlug || slug || video;
    if (trackSlug) {
      track('Favorite content', {
        slug: trackSlug,
        type,
        product_id: contentRelatedProduct?.id
          ? contentRelatedProduct?.id
          : contentRelatedProductId,
        product_name: contentRelatedProduct?.name,
      });
    }

    setDisableButton(false);
    setContent(response);

    dispatch({ type: FavoriteTypes.Create, payload: response });
  }, [
    user?._id,
    contentId,
    type,
    dispatch,
    slug,
    video,
    contentSlug,
    contentRelatedProduct,
    contentRelatedProductId,
    track,
  ]);

  const handleRemoveFavorite = useCallback(async () => {
    setToggle(false);
    setDisableButton(true);
    await FavoriteService.remove(content?.id);
    setDisableButton(false);
    track('Remove favorite content', {
      slug: content?.slug,
      type: content?.type,
    });

    dispatch({
      type: FavoriteTypes.Delete,
      payload: { favoriteId: content?.id },
    });
  }, [content?.id, content?.slug, content?.type, dispatch, track]);

  const handleFavorite = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event) event.stopPropagation();

      if (user?._id) {
        if (onClick) onClick(contentId);

        if (content) {
          handleRemoveFavorite();
        } else {
          handleCreateFavorite();
        }
      } else {
        setShowModalLogin(true);
      }
    },
    [
      content,
      handleCreateFavorite,
      handleRemoveFavorite,
      setShowModalLogin,
      user?._id,
      onClick,
      contentId,
    ],
  );

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (event) event.stopPropagation();
    setShowModal(true);
  };

  const favoriteButtons = useMemo(
    () => ({
      mobile: (
        <MobileButton
          handleFavorite={showModalConfirm ? openModal : handleFavorite}
          toggle={toggle}
          disableButton={disableButton}
          theme={theme}
        />
      ),
      desktop: (
        <DesktopButton
          handleFavorite={showModalConfirm ? openModal : handleFavorite}
          toggle={toggle}
          disableButton={disableButton}
        />
      ),
    }),
    [handleFavorite, toggle, disableButton, theme, showModalConfirm],
  );

  return (
    <>
      {favoriteButtons[buttonType]}{' '}
      <AlertConfirm
        callback={handleFavorite}
        onClose={() => setShowModal(false)}
        open={showModal}
        question="Tem certeza que deseja remover item dos favoritos?"
      />
    </>
  );
};

export default FavoriteButton;
