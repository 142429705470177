import { memo, useState, useRef, useEffect, useCallback } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';

import { Box } from '@material-ui/core';

import { useTracking } from '@hooks/tracking';

import { HiddenSm } from '@components/ui';
import Card, { ICardProps } from '../CardContent';

import {
  Container,
  ContainerHeader,
  Content,
  Title,
  LinkGroup,
  LinkGroupAll,
} from './styles';

const Arrows = dynamic(() => import('./ArrowsControl'));

export interface SliderProps {
  contents: ICardProps[];
  title?: string;
  link?: any;
  variant?: 'horizontal' | 'vertical';
  onClick?(e: Event): void;
  onClickFavorite?: (contendId: string) => void;
  showModalConfirm?: boolean;
  className?: string;
}

const Slider: React.FC<SliderProps> = ({
  contents,
  title,
  link,
  variant = 'vertical',
  onClick,
  onClickFavorite,
  showModalConfirm,
  className,
}) => {
  const { track } = useTracking();
  const perPage = 4;
  const refContent = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [page, setPage] = useState(1);
  const [contentsPage, setContentsPage] = useState(contents?.slice(0, 1));

  useEffect(() => {
    if (refContainer.current && contents?.length > 0) {
      setContentsPage(contents.slice(0, Math.round(containerWidth / 140 + 1)));
    }
  }, [contents, containerWidth]);

  const handleClientWidth = useCallback(() => {
    setContainerWidth(refContainer.current?.clientWidth);
  }, []);

  useEffect(() => {
    handleClientWidth();
    window.addEventListener('resize', handleClientWidth, {
      passive: true,
    });
    return () => {
      window.removeEventListener('resize', handleClientWidth);
    };
    // eslint-disable-next-line
  }, []);

  if (!contents || contents.length < 1) return null;

  const handleScroll = (event: 'next' | 'prev') => {
    if (refContent.current && refContainer.current) {
      const { scrollLeft } = refContent.current;
      const clientWidth = refContainer.current.clientWidth - 100;
      const valueScroll = event === 'next' ? clientWidth : -clientWidth;

      refContent.current.scrollTo({
        left: scrollLeft + valueScroll,
        behavior: 'smooth',
      });
    }
  };

  const handleNextPage = () => {
    if (contentsPage.length < contents.length) {
      setPage(page + 1);
      setContentsPage(contents.slice(0, (page + 1) * perPage));
    }
  };

  const handleOnClickViewAll = () => {
    track('Clicked at View All button', {
      title,
    });
  };

  return (
    <>
      <Container ref={refContainer} className={className}>
        <ContainerHeader>
          {title && <Title>{title}</Title>}

          <LinkGroup>
            {link && (
              <NextLink href={link}>
                <LinkGroupAll onClick={handleOnClickViewAll}>
                  Ver todos &#62;
                </LinkGroupAll>
              </NextLink>
            )}

            <HiddenSm>
              <Arrows
                onPressNext={() => handleScroll('next')}
                onPressPrev={() => handleScroll('prev')}
              />
            </HiddenSm>
          </LinkGroup>
        </ContainerHeader>
        <Content
          vertical
          horizontal
          hideScrollbars
          innerRef={refContent}
          onClick={onClick ? e => onClick(e) : null}
          onScroll={() => handleNextPage()}
          nativeMobileScroll
        >
          {contentsPage?.map(content => (
            <Card
              key={content.id}
              {...content}
              variant={variant}
              onClickFavorite={onClickFavorite}
              showModalConfirm={showModalConfirm}
            />
          ))}
          <Box minWidth="10px" height="1px" />
        </Content>
      </Container>
    </>
  );
};

export default memo(Slider);
