import { styled } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Content = styled(ScrollContainer)(({ theme }) => ({
  display: 'flex',

  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  scrollBehavior: 'smooth',

  [theme.breakpoints.down('sm')]: {
    '& > div:first-child': {
      marginLeft: '20px',
    },
  },
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 40px 40px',
  marginLeft: 40,
  borderRadius: '13px 0 0 13px',
  backgroundColor: '#ffffff',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    marginTop: 16,
  },
}));

export const ContainerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 16,
  paddingBottom: 32,
  width: '98%',
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
    paddingTop: 0,
    paddingBottom: 18,
  },
}));

export const Box = styled('div')(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 20,
  },
}));

export const Title = styled('h1')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: 28,
  fontWeight: 'bold',
  color: '#000000',
  margin: 0,

  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    margin: 'initial',
  },
}));

export const LinkGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',

  a: {
    color: '#0080ed',
    cursor: 'pointer',
  },
});

export const LinkGroupAll = styled('a')(({ theme }) => ({
  marginRight: 38,
  color: '#0080ed',
  fontSize: 12,
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    fontSize: 18,
  },
}));

export const LinkGroupBackFavorites = styled('a')(({ theme }) => ({
  color: '#0080ed',
  fontSize: 16,
  cursor: 'pointer',
  padding: '0 0 45px 20px',

  [theme.breakpoints.up('md')]: {
    fontSize: 18,
    padding: '0 0 60px 40px',
    marginTop: -35,
  },
}));
